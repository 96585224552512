.pill {
  margin-left: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 125%;
  background-color: #E9E9E9;
  border-radius: 70px;
  padding: 3px 8px;

  /* Neutral 70 */
  color: #474747;

  //cursor: pointer;

  &.selected {
    background: #0C84D6;
    color: #FFFFFF;

    padding-left: 8px;

    .check-icon {
      margin-right: 5px;
    }
  }


}
