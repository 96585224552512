/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.review-container {
  height: 100%;
  margin: 1.25em;
}

.good {
  background-color: #F2F8EE;
  color: #449711;
  padding: 0.25em;
  border-radius: 2px;
}
.bad {
  background-color: rgba(234,5,5,0.25);
  color: rgba(234,5,5,1);
  padding: 0.25em;
  border-radius: 2px;

}

.summary {
  font-size: 12px;
}
