/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.reviews {
  font-weight: 400;
  font-size: 12px;
  margin-right: 4px;
  color: #474747;
}
.value {
  margin-right: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #252525;
}
.label {
  font-weight: 400;
  font-size: 10px;
  line-height: 125%;
  color: #808080;
}
.metric-container {
  margin-bottom: 5.5px;
  display: flex;
  align-items: center;
}

.overlay-tooltip {
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  box-shadow: 0 1px 0 rgba(37, 37, 37, 0.05), 0 1px 3px rgba(37, 37, 37, 0.15);
  border-radius: 3px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  padding: 8px 12px;
  color: #252525;
}

.positive-negative {
  & > div {
    display: inline-block;
    margin-right: 1.75em;
  }

}

.tooltip {
  & > span{

    background: #FFFFFF;
    box-shadow: 0 11px 15px rgba(0, 0, 0, 0.1), 0 9px 46px rgba(0, 0, 0, 0.06), 0 24px 38px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 8px 12px;
  }
}

.tooltip-icon{
  width: 12px;
  height: 12px;
  border-radius: 4px;
  margin-right: 1em;
}

.tooltip-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  color: #252525;
}

.tooltip-label{
  display: flex;
  justify-content: space-between;
  margin: 1em 0.25em;
  color: #808080;
  div {
    margin-right: 1em;
  }
  & > span{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
    color: #808080;
  }

  & > .value {
    color: #252525;
  }
}
