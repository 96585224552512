/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.company-name {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 125%;
  color: #252525;
  margin-bottom: 0;

  span {
    padding: 0 8px;
  }
}

.tooltip {
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  box-shadow: 0 1px 0 rgba(37, 37, 37, 0.05), 0 1px 3px rgba(37, 37, 37, 0.15);
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 12px;
  color: #252525;
}

.filter {
  font-size: 0.75rem;
}

.link {
  font-weight: normal;
  text-decoration: none;
  color: #0c84d6;
  font-size: 12px;
  padding: 0.375em 16px !important;
}

.header {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  padding-right: 13px;
}

.card {
  border-radius: 6px;
  margin: 0 0 1em 0;
}

.button-group {
  box-sizing: border-box;
  width: 128px;
  height: 28px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
}


.header-container {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.attribute-highlights {
  flex: 1 1 auto;
  padding-left: 30px;
}


.review {
  padding-left: 30px;
}

.additional-title {
  margin-left: 1.5em;
  padding: 8px 12px 9px 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #449711;
  background: #F2F8EE;
  border-radius: 4px;
}

.ellipse {
  cursor: pointer;
  background: #F2F2F2;
  border-radius: 5px;
  padding: 3px;
  margin-right: 8px;
}

.review-highlights {
  & > div {
    height: 100%;
  }
}

.brand-control {
  & > div {
    height: 100%;
  }
}

.content-card {
  & > div {
    height: 100%;
  }
}

.good {
  background-color: #F2F8EE;
  color: #449711;
  padding: 0.25em;
  border-radius: 2px;
}

.summary {
  font-size: 12px;
}

.review-container {
  height: 100%;
  margin: 1.25em;
}

.good {
  background-color: #F2F8EE;
  color: #449711;
  padding: 0.25em;
  border-radius: 2px;
}

.summary {
  font-size: 12px;
}


.export {
  display: flex;
  align-items: center;
  padding: .5em;
  cursor: pointer;

  > span {
    margin-left: 12.5px;
  }
}

.brand-filter {
  display: flex;
  font-size: 12px;
  line-height: 16px;

  > div {
    background: #E9E9E9;
    border-radius: 4px;
    margin-right: .5em;
  }

  > div:last-of-type {
    margin-right: 0;
  }

}

.period {
  width: 190px;

  :global(.dropdown-menu) {
    width: 192px;
  }
}

.brand {
  width: 200px;

  :global(.dropdown-menu) {
    width: 202px;
  }
}

.dropdown-menu {
  font-size: 12px;
  line-height: 16px;

  > :global(a.dropdown-item) {
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
  }

  > :global(a.dropdown-item) {
    &:hover {
      background-color: #E6F5FF;
    }
  }
}

.dropdown {
  > :global(div.dropdown-menu.show) {
    background: #FFFFFF;
    transform: none !important;
  }

  :global(.dropdown-menu) {
    padding: 0;
    border-color: #f0f0f0;
  }
}

.filter-title {
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
}

.format-text {
  > span:first-of-type {
    color: #666666;
  }
  > span:last-of-type {
    color: #252525
  }
}
