
.header {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  padding-right: 13px;
}

.header-container {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.company-name {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 125%;
  color: #252525;
  margin-bottom: 0;

  span {
    padding: 0 8px;
  }
}

.tooltip {
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  box-shadow: 0 1px 0 rgba(37, 37, 37, 0.05), 0 1px 3px rgba(37, 37, 37, 0.15);
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 12px;
  color: #252525;
}

.search {
  width: 251px;
  height: 35px;
  display: inline-flex;
  line-height: 15px;
  font-size: 30px;
  margin-left: 6.5em;

}


.search-icon {
  background: transparent;
  color: #666666
}

.search-text {
  border-left: none;
}


.filter-button {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
  padding: 0;
  width: 200px;

  button {
    padding: 0;
    width: inherit;
    border: 1px solid #f0f0f0;
  }

  > :global(div.dropdown-menu.show) {
    background: #FFFFFF;
    transform: none !important;
  }

  :global(.dropdown-menu) {
    padding: 0;
    width: 202px;
  }
}

.dropdown-menu {
  font-size: 12px;
  line-height: 16px;

  > :global(a.dropdown-item) {
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
  }

  > :global(a.dropdown-item) {
    &:hover {
      background-color: #E6F5FF;
    }
  }
}

.filter-options {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:active {
    background-color: #666666;
    color: #252525;
  }

  &:hover {
    background-color: #E6F5FF;
  }
}

.format-text {
  font-size: 11px;

  > span:first-of-type {
    color: #666666;
  }

  > span:last-of-type {
    color: #252525
  }
}

.filter-title {
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
}

