.good {
  background-color: #ecf4ee;
  color: #00ba34;
  padding: 0.25em;
  border-radius: 2px;
}
.summary {
  font-size: 11px;
}
.container {
  display: flex;
  height: 260px;
  margin-top: 1em;
}

.meter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  margin-top: 0.5em;
  p {
    font-size: 10px;
    margin: 0;
    font-weight: 400;
    color: #666666;
  }
  p:first-child {
    font-weight: 700;
  }
}

img, svg {
  border-radius: 4px;
}

.coming-soon {
  padding: 8px 12px 9px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #449711;
  background: #F2F8EE;
  border-radius: 4px;
  margin: auto;
  justify-content: space-between;
}
