.growing-textarea {
  font-family: Public-sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  box-sizing: border-box;
  padding-bottom: 20px;

  /* Neutral 90 */
  color: #474747;

  &::placeholder {
    color: #929292;
  }
  max-height: 100px;
}

textarea {
  resize: none;
}

.textarea-size-buffer {
  border: none;
  height: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.char-count {
  color: #808080;
  font-size: 8px;
  padding-left: 1em;
  padding-top: 2px;
  text-align: left;
}

.updated-at {
  color: #808080;
  background-color: white;
  font-size: 10px;
  position: relative;
  right: -5px;
  text-align: right;
  stroke: white;
  top: -30px;
  width: 90%;
}
