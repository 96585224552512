$maxHeight: 100px;

.review-details-table {
  .table {
    margin: 0;

    tr:nth-child(odd) {
      background-color: #EFF4F6;
    }

    .header {
      background-color: white !important;
    }

    th {
      font-family: Public-sans, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 125%;
      vertical-align: middle;
      text-align: center;
      padding: 8px 12px;
      width: inherit;
      color: #666666;

      &.left {
        text-align: left;
      }
    }

    td {
      font-size: 12px;
      padding: 12px;
      text-align: center;

      &.left {
        text-align: left;
      }

      &.scrolling-cell {
        text-align: left;
        display: block;
        overflow-y: auto;
        height: 100%;
        max-height: $maxHeight;
        box-sizing: content-box;
      }

      .media-link {
        font-size: 12px;
        color: #252525;

        span, a span {
          margin-right: 10px;
        }

        &-active {
          color: #0C84D6;
          font-size: 12px;
          line-height: 14px;

          span, a span {
            margin-right: 10px;
          }
        }
      }
      .stars{
        white-space: nowrap;
        padding: 0;

        svg{
          padding: 2.2px
        }
      }


    }

    .table-row {
      height: 100%;
      max-height: $maxHeight;
      vertical-align: middle;
    }

    input[type='checkbox'] {
      width: 20px;
      height: 20px;

      &:checked {
        background-color: #0C84D6;
        border-color: #0C84D6;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: end;
    padding: 10px;
  }



  .centered-data {
    text-align: center;
  }


}

.tooltip {
  height: 32px;
  padding: 8px 12px 8px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(37, 37, 37, 0.05), 0 1px 3px rgba(37, 37, 37, 0.15);
}
