.highlight-item-container {
  .section {
    display: flex;
    margin: 12px 0 20px;

    img {
      height: 64px;
      width: 64px;
      border: 1px solid rgba(197, 197, 197, 0.4);
      padding: 4px;
      margin-right: 10px;

    }
    .item-details {
      display: flex;
      flex-direction: column;
      justify-content: end;
      font-size: 12px;

      .rating-container {
        position: relative;
        font-size: 16px;

        .star-container {
          position: absolute;
          top: -3px;
          left: 25px;

          svg{
            padding: 2.2px
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-weight: 400;
        color: #0C84D6;
      }
    }
  }


}
