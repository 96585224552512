/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.viz-bar {
  margin-right: 0.3em;
}

.review-card{
  background: #ffffff;
  border-radius: 6px;
  padding: 10px;
  white-space: nowrap;
  height: 132px;
  &.with-border {
    border: 1px solid #eeeeee;
  }
}

.star-container {
  display: inline;
  svg{
    padding: 2.2px
  }
}
