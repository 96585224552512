.modal-container{
  margin-top: 200px;
  .modal-body{
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 18px;

  }

  .modal-close-icon {
    cursor: pointer;
    //width: 20px;
    //height: 20px;
    float: right;
  }
  .title {
    margin: 26px 14px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }
  .modal-content {
    margin: 0 14px;

    .text-body {
      margin-top: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #474747;
      line-height: 20px;
    }
    .radio-group{
      margin: 12px 0 24px;
      color: #474747;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      flex-direction: column;

      .radio-option{
        margin-top: 12px;
        display: flex;
        align-items: center;
        input {
          margin-right: 12px;
        }
        label {
          line-height: 1;
        }
      }
    }
  }

  .modal-footer {
    align-self: end;

    .btn-group {
      margin-top: 16px;
      font-weight: 500;
      font-size: 16px;
      border-top: #E2E2E2 1px;

      .cancel-btn{
        color: #474747;
        background: #E9E9E9;
        border-radius: 4px;
        width: 81px;
        height: 44px;
        border: none;
        margin-right: 12px;
        padding: 12px 16px;
        line-height: 20px;
      }
      .disabled-btn{
        color: #FFFFFF;
        background: #929292;
        border-radius: 4px;
        border: none;
        height: 44px;
        width: 177px;
        padding: 12px 16px;
        opacity: 1;
        line-height: 20px;
      }
      .confirm-btn{
        color: #FFFFFF;
        background: #0C84D6;
        border-radius: 4px;
        border: none;
        height: 44px;
        padding: 12px 16px;
        line-height: 20px;
        width: 177px;
      }

    }
  }

  .line {
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    background: #E2E2E2;
  }

}

.modal-dialog{
  background: rgba(0, 0, 0, 0.29);
}

