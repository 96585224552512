/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.sidebar {
  flex-direction: column;
  background-color: #242f34;
  height: 100%;
  width: 250px;

  &.collapsed {
    width: 60px;
    .new-feature {
      display: none;
    }
    .coming-soon {
      color: transparent;
      font-size: 0;
    }
    & div:global(.nav-item), a {
      justify-content: center;
      & > span {
        display: none;
      }
    }
  }
}
.tooltip {
  background: #374D56;
  box-shadow: 0 1px 0 rgba(37, 37, 37, 0.05), 0 1px 3px rgba(37, 37, 37, 0.15);
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  padding: 9px 14px;
  color: #FFFFFF;
  margin-left: 10px;
}


.wrapper {
  padding: 0.2em;
  align-items: center;
  justify-content: start;
  margin: 0.8em;
  display: flex;
  width: 80%;
  height: auto;
  color: #C7C7C7;
  text-decoration: none;

  .brand {
    height: 30px;
    display: inline-flex;
    flex-direction: column;
    padding: 3px 3px 3px 10px;
    font-size: 12px;
    line-height: 16px;
  }

  &.inactive {
    cursor: default;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #808080;
    margin-left: 10px;
    & > span {
      margin-left: 10px;
    }
    & > .icon {
      color: #808080;
    }
  }
}

.active {
  background: #38474f;
  border-radius: 6px;

  & > span {
    color: #ffffff;
  }

  & > img {
    filter: invert(74%) sepia(54%) saturate(886%) hue-rotate(347deg)
      brightness(102%) contrast(81%);
  }
}

.collapse-nav {
  margin-top: 30px;
  flex-wrap: wrap;

  &:before {
    content: '';
    display: block;
    border-top: 1px solid #535e63;
    height: 30px;
    flex-basis: 100%;
  }
}

.coming-soon{
  display: inline-block;
  width: 80%;
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  color: #E2E2E2;
  margin-top: 20px;
  margin-bottom: 10px;
  &:before {
    content: '';
    display: block;
    border-top: 1px solid #535e63;
    height: 20px;
    flex-basis: 100%;
  }

}
.inactive-icon {
  width: 20px;
  height: 20px;
  filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(74deg) brightness(100%) contrast(86%);
}
.link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-left: 10px;
  color: #C7C7C7;
}

.icon {
  width: 20px;
  height: 20px;
  filter: invert(96%) sepia(9%) saturate(3%) hue-rotate(314deg) brightness(86%) contrast(82%);
}

.rotate {
  transform: rotate(180deg);
}

.spins-logo {
  width: 33px;
}

.spins-title {
  width: 50px;
}

.label {
  font-size: 12px;
  line-height: 24px;
  width: 120px;
  color: #fff;
}
