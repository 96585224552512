/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
  background: #2E3B41;
  border-radius: 6px;
  padding: 20px;
}


.title {
  font-style: normal;
  color: #E8B227;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  display: inline;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  color: #E9E9E9;
}

.button {
  font-size: 12px;
  background: transparent ;
  color: #E9E9E9;
}


.image{
  width: 100%;
  padding: 1em;
}
