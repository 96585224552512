/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
  .issue:last-of-type {
    border-color: transparent;
    padding-bottom: 0.5em;

  }
}
.issue-icon {
  margin-left: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 125%;
  background-color: #E9E9E9;
  border-radius: 70px;
  padding: 3px 8px;
  color: #474747;
}

.issue {
  font-size: 14px;
  padding: 16px 0 16px 0;
  margin: 0 16px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > a {
    color: #0C84D6;
  }

}

.coming-soon {
  padding: 8px 12px 9px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #449711;
  background: #F2F8EE;
  border-radius: 4px;
  //margin: auto;
}

.suppressed-buy-box{
  color: #929292;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;


}
