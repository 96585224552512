/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.company-name {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 125%;
  color: #252525;
  margin-bottom: 0;

  span {
    padding: 0 8px;
  }
}
.search {
  width: 251px;
  height: 35px;
  display: inline-flex;
  line-height: 15px;
  font-size: 30px;
  margin-left: 6.5em;
}

.search-icon {
  background: transparent;
  color: #666666
}

.search-text {
  border-left: none;
}

.header {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  padding-right: 13px;
}

.header-container {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}
