.item-row {
  margin-top: 10px;
  margin-bottom: 20px;
  --bs-gutter-x: 0;
}

.item-mention {

  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 125%;
  word-break: break-word;
  padding: 2px 8px;

  /* Blue 60 */
  color: #0c84d6;
  background-color: #F4F6F8;
  cursor: default;

  span{
    padding: 2px 8px;
    background: #f4f6f8;
  }

  &:hover {
    background-color: #E6F5FF;
  }


}

.item-mention.selected{
  background: #0C84D6;
  color: #FFFFFF;
}

.item-count {
  background: #e9e9e9;
  border-radius: 100px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 125%;
  text-align: center;
  /* Neutral 90 */
  color: #474747;
}
.star{
  line-height: 100%;
  white-space: nowrap;
  padding: 0;

  svg{
    padding: 2.2px
  }
}

.star-rating-value {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  /* Neutral 100 */
  color: #252525;
  margin-left: 5px;
  align-content: center;
}
