.title-row {
  --bs-gutter-x: 0;
  &> div {
    font-family: Public-sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    /* Neutral 90 */
    color: #474747;
  }

}

.no-issues-container{
  display: flex ;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  .congrats{
    font-size: 16px;
    font-weight: 600;

  }

  .no-issues{
    font-size: 12px;
    color: #666666;
  }


  & > .icons{
    display: flex ;
    margin-top: 32px;
    flex-direction: row;
    align-content: space-between;

    .left{
      margin-right: 15px;
      direction: rtl;
      display: flex ;
      flex-direction: column;
      justify-content: left;

      span{
        direction: rtl;
      }
    }
    .right{
      margin-bottom: -40px;
      display: flex;
      align-content: flex-end;
      flex-direction: row;
    }
  }
}









