/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
@media only screen and (max-width: 1024px) {
  .content {
    margin: 0 !important;
  }
}

.content {
  overflow-x: auto;
  max-width: 1500px;

  &::-webkit-scrollbar-thumb {
    background-color: #e8b227;
  }

  &::-webkit-scrollbar-track {
    background: #e6e6e6;
    border-radius: 1px;
  }

  &::-webkit-scrollbar {
    background: #e8b227;
    border-radius: 2px;
    height: 4px;
  }

  background-color: #f2f2f2;
  margin: 0 20px 20px 20px;
}

.container {
  overflow-x: clip;
  background-color: #f0f0f0;
  min-width: 1076px;

  & > div {
    margin: 0.5em;
  }
}

.sidebar {
  padding-right: 0;
}


.rotate {
  transform: rotate(180deg);
}

.nav-icons {
  align-items: center;

  & > div {
    display: inline;
    margin: 0 1em;
  }

  &:after {
    content: '';
    border-right: 1px solid #e2e2e2;
    height: 100%;
    margin: 0 1em;
  }
}

.app-switcher {
  > :global(.dropdown-menu) {
    left: -5.5em !important;
    right: auto;
  }
}

.app-menu {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 4px 4px 0 0;




  & > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #252525;
    margin-left: 16px;
    margin-right: 5em;
  }
}

.a-style {
  text-decoration: none;
  color: unset;
}

