/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  height: 100%;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  color: #252525;
  display: inline-block;
  margin-bottom: 8px;
}

.card {
  padding: 16px !important;
  border: 1px solid #E9E9E9;
  border-radius: 6.5928px;
  height: 100%;
}

.coming-soon {
  padding: 8px 12px 9px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #449711;
  background: #F2F8EE;
  border-radius: 4px;
  margin: auto;
}
