/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.section{
  padding-bottom: 20px;
}
.review-highlight {
  font-weight: 600;
  font-size: 12px;

}

p {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}

.badge {
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  color: #0C84D6;
  background: #F4F6F8;
  border-radius: 2px;
  margin: 4px 8px 0 0;
  display: inline-flex;
}

