.footer {
  justify-content: center;
}

.footer-span {
  font-size: 12px;
  padding: 10px;
}


select {
  font-size: 12px;
  border-radius: 4px;
  width: 59px;
  height: 32px;
  text-align-last: center;

}

.footer-button {
  font-size: 12px;
  border-radius: 4px;
  margin: 0 3px;
  width: 40px;
  height: 32px;
  background-color: #E9E9E9;
  border: none;
}
