/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.value {
  font-size: 28px;
}

.title {
  font-size: 14px;
}
.period-comparison {
  font-size: 11px;
  color: #474747;
  font-weight: 400;
  padding-left: 8px;
}


.container {
  background: #ffffff;
  border-radius: 6px;
  padding: 1rem;
  white-space: nowrap;
  height: 132px;
  &.with-border {
    border: 1px solid #eeeeee;
  }


}

.title-row {
  & > :nth-child(1) {
    width: 125px;
  }
  & > :nth-child(2) {
    width: calc(100% - 133px);
  }
}

.badge-container{
  padding-top: 10px;
}

.small-chart-quad{
  padding-top: 15px;
  margin-left: 8px;
}
.small-chart-week{
  padding-top: 15px;
}

.tooltip {
  & > span{
    min-width: 10em;
    background: #FFFFFF;
    box-shadow: 0 11px 15px rgba(0, 0, 0, 0.1), 0 9px 46px rgba(0, 0, 0, 0.06), 0 24px 38px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 8px 12px;
  }
}
