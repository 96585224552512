.rating-table {
  .table {
    margin: 0;
    //> tr:nth-child(odd) {
    // background-color: #EFF4F6;
    // }
    thead {
      height: 60px;
      tr {
        height: 60px;

        th{
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          //padding: 22.5px 12px 22.5px 12px !important;
          padding: 8px 12px;
          width: inherit;
          color: #666666;
          vertical-align: middle;
          height: 60px;

          &.left {
            text-align: left;
          }
          div {
            display: flex;
            align-items: center;
            span {
              margin-right: 5px;
            }
          }
        }
      }
    }

    tbody {
      tr:nth-child(odd) {
        background-color: #EFF4F6;
      }

      tr {
        height: 48px;

        td{
          font-size: 12px;
          height: 48px;
          text-align: center;

          &.scrolling-cell {
            text-align: left;
            display: block;
            border: none;
            overflow-y: auto;
            box-sizing: content-box;
            max-height: 102px;
            height: auto;
            padding: 12px
          }

          &.left {
            text-align: left;
          }

          &.right {
            text-align: right;
          }

          a {
            color: #0C84D6
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: end;
    padding: 10px;
  }
  .header{
    background-color: white !important;
  }

  .centered-data{
    text-align: center;
  }

  .stars{
    display: block;
    white-space: nowrap;
    padding: 0;

    svg{
      padding: 2.2px
    }
  }
}
