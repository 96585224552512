.container {
  padding: 8px;
  border-radius: 6px;
  width: 100%;
}

.tooltip {
  & > span{

    background: #FFFFFF;
    box-shadow: 0 11px 15px rgba(0, 0, 0, 0.1), 0 9px 46px rgba(0, 0, 0, 0.06), 0 24px 38px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 8px 12px;
  }
}

.tooltip-icon{
  width: 12px;
  height: 12px;
  border-radius: 4px;
  margin-right: 1em;

}

.tooltip-label{
  display: flex;
  justify-content: space-between;
  margin: 1em 0.25em;
  color: #808080;
  div {
    margin-right: 1em;
  }
  & > span{
    font-weight: 500;
    font-size: 12px;
    text-align: right;
    color: #252525;
  }
}
