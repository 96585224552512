/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/


.coming-soon {
  padding: 8px 12px 9px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #449711;
  background: #F2F8EE;
  border-radius: 4px;
  margin: auto;
}
