/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 320px;
}
