$maxHeight: 100px;

.item-sales-table {
  .table {
    margin: 0;

    tr:nth-child(odd) {
      background-color: #EFF4F6;
    }

    .header {
      background-color: white !important;
    }

    tbody {
      tr:nth-child(odd) {
        background-color: #EFF4F6;
      }

      tr {
        height: 48px;

        td{
          font-size: 12px;
          height: 48px;
          text-align: center;

          &.scrolling-cell {
            text-align: left;
            display: block;
            border: none;
            overflow-y: auto;
            box-sizing: content-box;
            max-height: 102px;
            height: auto;
            padding: 12px
          }

          &.left {
            text-align: left;
          }

          &.right {
            text-align: right;

          }

          a {
            color: #0C84D6
          }


        }

      }
    }

    th {
      font-family: Public-sans, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 125%;
      vertical-align: middle;
      text-align: center;
      padding: 8px 12px;
      width: inherit;
      color: #666666;

      &.left {
        text-align: left;
      }
    }

    td {
      font-size: 12px;
      padding: 12px;
      text-align: center;

      &.left {
        text-align: left;
      }

      &.scrolling-cell {
        text-align: left;
        display: block;
        overflow-y: auto;
        height: 100%;
        max-height: $maxHeight;
        box-sizing: content-box;
      }
    }

    .table-row {
      height: 100%;
      max-height: $maxHeight;
      vertical-align: middle;
    }

  }
  .footer {
    display: flex;
    justify-content: end;
    padding: 10px;
  }


  .arrow-icon{
    margin-right: 4px;
  }


}

.tooltip{
  font-weight: 400;
  line-height: 16px;
  color: #252525;
  background-color: #FFFFFF;
  padding: 8px 12px;;
  border-radius: 4px;
  border: 1px solid var(--neutral-20, #E2E2E2);
  background: var(--neutral-0, #FFF);
  box-shadow: 0 1px 3px 0 rgba(37, 37, 37, 0.15), 0 1px 0 0 rgba(37, 37, 37, 0.05);
}

.cell {
  cursor: default;

}
