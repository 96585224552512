.label {
  margin-right: 12px;
}

.brand-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
}

.container {
  display: flex;
  justify-content: center;
  background: #ffffff;
  height: 132px;
}

.coming-soon {
  padding: 8px 12px 9px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #449711;
  background: #F2F8EE;
  border-radius: 4px;
  margin: auto;
}
//.container {
//  background: #ffffff;
//  border-radius: 6px;
//  padding: 1rem;
//  white-space: nowrap;
//  height: 132px;
//  &.with-border {
//    border: 1px solid #eeeeee;
//  }
//}
