.brand-filter {
  display: flex;
  font-size: 12px;
  line-height: 16px;

  > div {
    background: #FFFFFF;
    border-radius: 4px;
    margin-right: .5em;
  }

  > div:last-of-type {
    margin-right: 0;
  }
}

.export {
  display: flex;
  align-items: center;
  padding: .5em;
  cursor: pointer;
  background: #FFFFFF;
  border-radius: 4px;
  border: none;

  > span {
    margin-left: 12.5px;
  }
}

.period {
  width: auto;

  :global(.dropdown-menu) {
    width: auto;
  }
}

.dropdown {
  > :global(div.dropdown-menu.show) {
    background: #FFFFFF;
    transform: none !important;
  }

  :global(.dropdown-menu) {
    padding: 0;
    border-color: #f0f0f0;
  }
}

.dropdown-menu {
  font-size: 12px;
  line-height: 16px;

  > :global(a.dropdown-item) {
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
  }

  > :global(a.dropdown-item) {
    &:hover {
      background-color: #E6F5FF;
    }
  }
}

.filter-title {
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
}

.format-text {
  margin-right: 1em;

  > span:first-of-type {
    color: #666666;
  }

  > span:last-of-type {
    color: #252525
  }
}

.brand {
  width: 156px;

  :global(.dropdown-menu) {
    width: auto;
  }
}
