/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
}
