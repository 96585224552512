.container {
  background-color: #FFFFFF;
  margin: 0 0 1em 0;
  border-radius: 4px;
  height: 330px;

  ul{
    :hover{
      color:#252525
    }
  }
}

.content-container {
  padding: 10px 10px 10px 16px;
}

.image {
  border: none;
  margin-bottom: 12px;
  max-height: 172px;
  max-width: 180px;
  height: auto;
  width: auto;
}

.details-title {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-decoration-line: none;

  /* Blue 60 */
  color: #252525;
}

.detail-item-label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;

  /* Neutral 100 */
  color: #252525;
}

.detail-item-value {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;

  /* Neutral 80 */
  color: #666666;
}

.competitor-rating{
  padding: 0 23px 0 23px;
}

.top-5{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  color: #252525;
}

.product-details{
  padding: 16px;
  background-color: #F4F7F9;
  border-radius: 8px;
  width: 297px;
  letter-spacing: 0.01em;
  color: #252525;
  font-size: 14px;
  line-height: 125%;

  > div {
    margin-bottom: 8.5px;
  }
}

.amazon-link {
  font-weight: normal;
  text-decoration: none;
  color: #0C84D6;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.coming-soon {
  padding: 8px 12px 9px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #449711;
  background: #F2F8EE;
  border-radius: 4px;
  margin: auto;
}

